import Moralis from "moralis";
import ExecuteFunctionSendResult = Moralis.ExecuteFunctionSendResult;

interface Contract {
  contractAddress: string,
  abi: any[],
}

export default async function contractPromise(functionName: string, params: any, contract: Contract, msgValue?: string):Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await Moralis.executeFunction({
        functionName,
        params,
        msgValue,
        ...contract,
      }) as unknown as ExecuteFunctionSendResult;

      // @ts-ignore
      const waitPromise = resp.wait();
      await waitPromise;
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(false);
    }
  });
}

export async function contractTypedPromise<T>(functionName: string, params: any, contract: Contract):Promise<T | false> {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await Moralis.executeFunction({
        functionName,
        params,
        ...contract,
      });

      resolve(resp as unknown as T);
    } catch (e) {
      console.error(e);
      reject(false);
    }
  });
}
