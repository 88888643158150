import React, {useState, Suspense, useEffect, useCallback} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  availableProfitAtom,
  dailyIncomeSelector,
  isRegisteredSelector,
  launchTimestampSelector,
  loggedInAccountAtom,
  minDepositSelector,
  minReinvestSelector,
  minWithdrawSelector,
  refState,
  userBalanceAtom,
  useUpdateBalance,
  useUpdateUserInfo,
  useIsBalanceEnough, userInfoSelector,
} from "@stores/accountStore";
import FormattedNumber from "@components/common/FormattedNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingDollar} from "@fortawesome/pro-duotone-svg-icons/faHandHoldingDollar";
import {faCoins} from "@fortawesome/pro-duotone-svg-icons/faCoins";
import {faSackDollar} from "@fortawesome/pro-duotone-svg-icons/faSackDollar";
import {useTranslation} from "react-i18next";
import {
  add,
  convertNumberToString,
  divide,
  greaterThan,
  multiply,
  smallerThan,
} from "@helpers/bignumber";
import {Modal} from "react-bootstrap";
import Preloader from "@components/common/Preloader";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import IconWithLoading from "@components/common/IconWithLoading";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {
  depositTokens,
  getAdmin,
  getUser,
  registerAccount,
  registerAndDeposit,
  reinvest,
  withdraw
} from "@contracts/BullFarm";
import {toast} from "react-hot-toast";
import confirmAlert from "@components/ConfirmAlert";
import Countdown from "react-countdown";
import {currentLanguage, Languages} from "@stores/languageStore";
import {faUser} from "@fortawesome/pro-solid-svg-icons";
import {faSignOut} from "@fortawesome/pro-duotone-svg-icons/faSignOut";
import {useMoralis} from "react-moralis";
import {roundDown} from "@helpers/numbers";
import {faChartPie} from "@fortawesome/pro-duotone-svg-icons/faChartPie";

export default function Wallet() {
  const {isAuthenticating, logout} = useMoralis();
  const [ethValue, setEthValue] = useState('0');
  const {t} = useTranslation();
  const balance = useRecoilValue(userBalanceAtom);
  const refIdState = useRecoilValue(refState);
  const [refAddress, setRefAddress] = useState<string>(refIdState || '');
  const [adminAddress, setAdminAddress] = useState<string>('');
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isRefAddressLoading, setRefAddressLoading] = useState<boolean>(false);
  const [regForAdmin, setForAdmin] = useState<boolean>(false);
  const [isApproved, setApproved] = useState<boolean | null>(false);
  const dailyIncome = useRecoilValue(dailyIncomeSelector);
  const [userAddress, setUserAddress] = useRecoilState(loggedInAccountAtom);
  const launchTimestamp = useRecoilValue(launchTimestampSelector);
  const isRegistered = useRecoilValue(isRegisteredSelector);
  const availableProfit = useRecoilValue(availableProfitAtom);
  // const availableProfit = '0';
  const isLaunched = launchTimestamp > 0;
  const updateUserInfo = useUpdateUserInfo();
  const updateBalance = useUpdateBalance();
  const minDeposit = useRecoilValue(minDepositSelector);
  const minWithdraw = useRecoilValue(minWithdrawSelector);
  const minReinvest = useRecoilValue(minReinvestSelector);
  const language = useRecoilValue(currentLanguage);
  const startDate = new Date('2022-10-14T09:00:00Z');
  const isEnough = useIsBalanceEnough();
  const userInfo = useRecoilValue(userInfoSelector);
  const [showDetails, setShowDetails] = useState(false);

  const partnersReward = userInfo?.partnersRewardByLine.reduce((acc, cur) => acc + cur, 0) || 0;
  const maximumAvailable = multiply(userInfo?.deposit || 0, 3);

  const partnersRewardPercent = divide(multiply(partnersReward, 100), maximumAvailable);
  const withdrawPercent = divide(multiply(userInfo?.withdraw || 0, 100), maximumAvailable);
  const availablePercent = divide(multiply(availableProfit, 100), maximumAvailable);
  const isAvailableExceed = greaterThan(add(availablePercent, add(partnersRewardPercent, withdrawPercent)), 100);

  const handleLogout = () => {
    setUserAddress(null);
    logout();
  }

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const register = useCallback(() => {
    if (refAddress && !isApproved) {
      toast.error(t('error.approve-upline'), {duration: 5000});
      return;
    }

    if (refAddress === userAddress) {
      toast.error(t('error.default'), {duration: 5000});
      return;
    }

    setLoading(true);

    const zeroRef = adminAddress || '0x0000000000000000000000000000000000000000';

    try {
      const waitPromise = isLaunched ? registerAndDeposit(refAddress || zeroRef, ethValue) : registerAccount(refAddress || zeroRef);

      toast.promise(waitPromise, {
        loading: t('alert.processing'),
        success: t('alert.register-complete') + '',
        error: t('alert.register-error'),
      });

      waitPromise
        .then(() => {
          updateUserInfo();
          updateBalance();
          setLoading(false);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (e) {
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    }
  }, [adminAddress, updateUserInfo, updateBalance, isApproved, refAddress, userAddress, isLaunched, ethValue, t]);

  const loadRefAddress = (customRef?: string, isAuto = false) => {
    setApproved(false);
    if (!refAddress && !customRef) {
      return;
    } else if (customRef) {
      setForAdmin(true);
    }

    setRefAddressLoading(true);

    try {
      getUser(customRef || refAddress || '')
        .then((result) => {
          if (result && result.account !== '0x0000000000000000000000000000000000000000') {
            setApproved(true);
          } else {
            if (isAuto) {
              setRefAddress('');
            } else {
              setApproved(null);
              console.error(result);
              toast.error(t('error.user-address-not-found'), {duration: 5000});
            }
          }
          setRefAddressLoading(false);
        })
        .catch((e) => {
          if (isAuto) {
            setRefAddress('');
          } else {
            setApproved(null);
            console.error(e);
            toast.error(t('error.user-address-not-found'), {duration: 5000});
          }
          setRefAddressLoading(false);
        });
    } catch (e) {
      if (isAuto) {
        setRefAddress('');
      } else {
        setApproved(null);
        console.error(e);
        toast.error(t('error.default'), {duration: 5000});
      }
      setRefAddressLoading(false);
    }
  };

  const handleDeposit = useCallback(() => {
    setLoading(true);

    try {
      const waitPromise = depositTokens(ethValue);

      toast.promise(waitPromise, {
        loading: t('alert.processing'),
        success: t('alert.deposit-complete').replace('%value', ethValue),
        error: t('alert.deposit-error'),
      });

      waitPromise
        .then(() => {
          updateUserInfo();
          updateBalance();
          setLoading(false);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (e) {
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    }
  }, [ethValue, t, updateUserInfo, updateBalance]);

  const handleDepositOrRegister = useCallback(() => {
    if (isLaunched && (smallerThan(ethValue, minDeposit) || !ethValue)) {
      toast.error(t('error.deposit-min').replace('%value', convertNumberToString(minDeposit)), {duration: 5000});
      return;
    } else if (!isEnough(ethValue)) {
      return;
    } else if (!isRegistered) {
      if (refAddress) {
        handleShow();
      } else {
        confirmAlert({
          title: t('register.title'),
          okLabel: t('common.yes'),
          cancelLabel: t('common.no'),
          confirmation: <div className="tx-20 tx-bold">{t('register.have-upliner')}</div>
        }).then((result) => {
          if (!!result) {
            handleShow();
          } else {
            register();
          }
        });
      }
    } else {
      handleDeposit();
    }
  }, [register, isEnough, refAddress, isLaunched, ethValue, minDeposit, isRegistered, handleDeposit, t]);

  const handleReinvest = useCallback(() => {
    if (!availableProfit || smallerThan(availableProfit, minReinvest)) {
      toast.error(t('error.reinvest-min').replace('%value', convertNumberToString(minReinvest)), {duration: 5000});
      return;
    }

    setLoading(true);

    confirmAlert({
      title: t('alert.confirm-reinvest-title'),
      confirmation: (
        <>
          {t('alert.confirm-reinvest').replace('%value', availableProfit)}
        </>
      ),
      okLabel: t('common.reinvest'),
      okIcon: faCoins,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = reinvest();

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.reinvest-complete').replace('%value', availableProfit),
            error: t('alert.reinvest-error'),
          });

          waitPromise
            .then(() => {
              updateUserInfo();
              updateBalance();
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              toast.error(t('error.default'), {duration: 5000});
              setLoading(false);
            });
        } catch (e) {
          console.error(e);
          toast.error(t('error.default'), {duration: 5000});
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })
    .catch((e) => {
      console.error(e);
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    });
  }, [availableProfit, minReinvest, t, updateUserInfo, updateBalance]);

  const handleWithdraw = useCallback(() => {
    if (!availableProfit || smallerThan(availableProfit, minWithdraw)) {
      toast.error(t('error.withdraw-min').replace('%value', minWithdraw.toString()), {duration: 5000});
      return;
    }

    setLoading(true);

    confirmAlert({
      title: t('alert.confirm-withdrawal-title'),
      confirmation: (
        <>
          {t('alert.confirm-withdrawal').replace('%value', roundDown(availableProfit, 6) + '')}
        </>
      ),
      okLabel: t('common.withdraw'),
      okIcon: faHandHoldingDollar,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = withdraw();

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.withdrawal-complete').replace('%value', roundDown(availableProfit, 6) + ''),
            error: t('alert.withdrawal-error'),
          });

          waitPromise
            .then(() => {
              updateUserInfo();
              updateBalance();
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              toast.error(t('error.default'), {duration: 5000});
              setLoading(false);
            });
        } catch (e) {
          console.error(e);
          toast.error(t('error.default'), {duration: 5000});
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })
    .catch((e) => {
      console.error(e);
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    });
  }, [availableProfit, minWithdraw, t, updateUserInfo, updateBalance]);

  useEffect(() => {
    setApproved(false);
  }, [refAddress]);

  useEffect(() => {
    if (regForAdmin && refAddress && !isRefAddressLoading) {
      register();
    }
  }, [register, regForAdmin, refAddress, isRefAddressLoading]);

  useEffect(() => {
    getAdmin().then((admin) => setAdminAddress(admin));
  }, []);

  return (
    <Suspense fallback={<Preloader/>}>
      <div className="d-flex wd-100p justify-content-end">
        <button className="btn btn-dark" disabled={isAuthenticating || isLoading} onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOut} className="me-2"/>
          Sign Out
        </button>
      </div>

      <div className="wd-sm-300 wd-md-400 mt-3">
        {(isLaunched || isRegistered === true) && (
          <>
            <div className="tx-center h2">{t('home.available-profit')}:</div>
            <FormattedNumber
              value={availableProfit}
              decimals={6}
              floor={true}
              postfix="ETH"
              className="tx-center wd-100p d-block h1 my-3"
            />
            <div className="tx-center wd-100p d-flex justify-content-between mb-3">
              <button className="btn btn-outline-primary me-1 wd-100p" onClick={handleWithdraw} disabled={isLoading}>
                <FontAwesomeIcon icon={faHandHoldingDollar} className="me-2"/>
                {t('common.withdraw')}
              </button>

              <button className="btn btn-primary ms-1 wd-100p" onClick={handleReinvest} disabled={isLoading}>
                <FontAwesomeIcon icon={faCoins} className="me-2"/>
                {t('common.reinvest')}
              </button>
            </div>

            {!!userInfo?.deposit && (
              <>
                <div className="mb-1 tx-info cur-pointer" onClick={() => setShowDetails((prevState) => !prevState)}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2"/>
                  {showDetails ? 'Hide' : 'Show'} Details
                </div>

                {showDetails && (
                  <div className="row mb-1">
                    <div className="col-6 mb-2">
                      Withdrawn <span className="badge bg-primary tx-dark">
                        <FormattedNumber
                          value={userInfo?.withdraw}
                          decimals={5}
                          floor={true}
                        />
                      </span>
                    </div>
                    <div className="col-6 mb-2 tx-right">
                      Available <span className={`badge ${isAvailableExceed ? 'bg-danger' : 'bg-purple'}`}>
                        <FormattedNumber
                          value={availableProfit}
                          decimals={5}
                          floor={true}
                        />
                      </span>
                    </div>
                    <div className="col-6 mb-2">
                      Partners <span className="badge bg-info">
                        <FormattedNumber
                          value={partnersReward}
                          decimals={5}
                          floor={true}
                        />
                      </span>
                    </div>
                    <div className="col-6 mb-2 tx-right">
                      Maximum <span className="badge bg-dark">
                        <FormattedNumber
                          value={maximumAvailable}
                          decimals={5}
                          floor={true}
                        />
                      </span>
                    </div>
                  </div>
                )}

                <div className={`progress ${isAvailableExceed ? 'bg-danger' : 'bg-dark'}`}>
                  {smallerThan(0.5, withdrawPercent) && (
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{width: withdrawPercent + '%'}}
                      aria-valuenow={parseFloat(withdrawPercent)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  )}
                  {smallerThan(0.5, partnersRewardPercent) && (
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{width: partnersRewardPercent + '%'}}
                      aria-valuenow={parseFloat(partnersRewardPercent)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  )}
                  {!isAvailableExceed && (
                    <div
                      className="progress-bar bg-purple"
                      role="progressbar"
                      style={{width: availablePercent + '%'}}
                      aria-valuenow={parseFloat(availablePercent)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}

        <Suspense fallback={<Preloader />}>
          {!isLaunched && (
            <div className="tx-center wd-100p mt-4 tx-20">
              <div>{t('register.will-be-launched')}</div>
              <Countdown
                date={startDate}
                intervalDelay={1000}
                precision={3}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                  if (!completed) {
                    const formatted = (time: number) => time < 10 ? '0' + time : time;

                    const day = () => {
                      let result = days === 1 ? 'day' : 'days';

                      // if (language === Languages.RU) {
                      //   result = days === 1 ? 'день' : 'дня';
                      // }

                      return result;
                    };

                    return (
                      <span className="tx-bold tx-30">
                        {days !== 0 ? days + ' ' + day() + ' ' : ''}
                        {formatted(hours)}:{formatted(minutes)}:{formatted(seconds)}
                      </span>
                    )
                  }
                  return null;
                }}
              />
            </div>
          )}

          {!isLaunched && isRegistered === false && (
            <div className="tx-center wd-100p mt-5">
              <button className="btn btn-primary" onClick={handleDepositOrRegister}>
                <FontAwesomeIcon icon={faUser} className="me-2"/>
                {t('common.register')}
              </button>
            </div>
          )}

          {isLaunched && (
            <>
              <div className="input-group mt-3 mb-1 wd-100p mx-auto">
                <input
                  type="number"
                  className="form-control tx-center appearance-none"
                  value={ethValue}
                  onChange={(e) => setEthValue(e.target.value)}
                  disabled={isLoading}
                />
                <button className="btn btn-primary" onClick={handleDepositOrRegister} disabled={isLoading}>
                  <FontAwesomeIcon icon={faSackDollar} className="me-2"/>
                  {t('common.deposit')}
                </button>
              </div>

              <div className="mt-2 wd-100p">
                {t('common.your-balance')}:
                <FormattedNumber
                  value={balance}
                  postfix="ETH"
                  floor={true}
                  decimals={5}
                  className="ms-1"
                />
              </div>

              <div className="mt-3 wd-100p tx-center">
                <div className="tx-bold tx-20">{t('common.income')}:</div>

                <div className="d-flex flex-column flex-md-row justify-content-between mt-1">
                  <div className="badge border-1 border-solid border-primary text-primary wd-100p p-2 tx-16 mb-1 mb-md-0 me-md-1">
                    <FormattedNumber
                      value={dailyIncome ? dailyIncome : 0}
                      postfix={`ETH ${t('tick.daily')}`}
                      decimals={4}
                      floor
                    />
                  </div>

                  <div className="badge border-1 border-solid border-primary text-primary wd-100p p-2 tx-16 mt-1 mt-md-0 ms-md-1">
                    <FormattedNumber
                      value={dailyIncome ? multiply(dailyIncome, 7) : 0}
                      postfix={`ETH ${t('tick.weekly')}`}
                      decimals={4}
                      floor
                    />
                  </div>
                </div>

                {greaterThan(ethValue, 0) && (
                  <div className="mt-1">
                    <div className="tx-semibold">{t('common.after-deposit')}:</div>
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-1">
                      <div className="badge border-1 border-solid border-whitey tx-white wd-100p p-1 tx-14 mb-1 mb-md-0 me-md-1">
                        <FormattedNumber
                          suffix="+"
                          value={multiply(ethValue, 0.02)}
                          postfix={`ETH ${t('tick.daily')}`}
                          decimals={4}
                          floor
                        />
                      </div>

                      <div className="badge border-1 border-solid border-white tx-white wd-100p p-1 tx-14 mt-1 mt-md-0 ms-md-1">
                        <FormattedNumber
                          suffix="+"
                          value={multiply(ethValue, 0.14)}
                          postfix={`ETH ${t('tick.weekly')}`}
                          decimals={4}
                          floor
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Suspense>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!isLaunched ? t('common.register') : t('register.first-deposit')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div>{t('register.upline-input')}</div>
          <div className="d-flex flex-column flex-lg-row mt-2">
            <input
              type="text"
              className={`form-control mb-0 me-2 flex-1 ${isApproved === true ? 'is-valid' : ''} ${isApproved === null ? 'is-invalid' : ''}`}
              disabled={isLoading || isRefAddressLoading}
              value={refAddress}
              onChange={(event) => setRefAddress(event.target.value)}
            />
            <button
              className="btn btn-sm btn-primary mt-2 mt-lg-0"
              type="button"
              onClick={() => loadRefAddress()}
              disabled={isLoading || isRefAddressLoading || !refAddress}
            >
              <IconWithLoading icon={faCheck} className="me-2" isLoading={isLoading || isRefAddressLoading}/>
              {t('register.approve-upline')}
            </button>
          </div>

          {isLaunched && (
            <FormattedNumber
              value={ethValue}
              postfix="ETH"
              className="tx-center h1 mt-5 tx-center d-block wd-100p"
            />
          )}
        </Modal.Body>

        <Modal.Footer className="pt-3 wide-buttons">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>

          {isLaunched && (
            <button className="btn btn-primary ms-3" onClick={register} disabled={false}>
              {t('common.deposit')}
              <IconWithLoading icon={faSackDollar} className="float-end ms-2" isLoading={isLoading || isRefAddressLoading}/>
            </button>
          )}
          {!isLaunched && (
            <button className="btn btn-primary ms-3" onClick={register} disabled={false}>
              {t('common.register')}
              <IconWithLoading icon={faUser} className="float-end ms-2" isLoading={isLoading || isRefAddressLoading}/>
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </Suspense>
  );
}
