import {atom, selector, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  getAvailableProfit,
  getLaunchTimestamp,
  getMinDepositValue,
  getMinReinvestValue,
  getMinWithdrawValue,
  getUser,
  UserInfo
} from "@contracts/BullFarm";
import {convertStringToNumber, greaterThanOrEqual, multiply} from "@helpers/bignumber";
import {useCallback, useEffect} from "react";
import {useMoralis} from "react-moralis";
import Moralis from "moralis";
import {toast} from "react-hot-toast";
import {useLocation} from "react-router-dom";
import useInterval from "@hooks/useInterval";
import {useTranslation} from "react-i18next";

export const refAtom = atom<string | null>({
  key: 'refId',
  default: localStorage.getItem('referral'),
});

export const loggedInAccountAtom = atom<string | null>({
  key: 'loggedInAccountAtom',
  default: null,
});

export const refState = selector<string | null>({
  key: 'refState',
  get: ({get}) => {
    return get(refAtom);
  },
  set: ({set}, newValue) => {
    set(refAtom, newValue);
    localStorage.setItem('referral', newValue ? newValue as string : '');
  },
});

export const userInfoUpdateIdentifier = atom<number>({
  key: 'userInfoUpdateIdentifier',
  default: 0,
});

export const profitUpdateIdentifier = atom<number>({
  key: 'profitUpdateIdentifier',
  default: 0,
});

export const userInfoSelector = selector<UserInfo | null>({
  key: 'userInfoSelector',
  get: async ({get}) => {
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return null;
    }

    get(userInfoUpdateIdentifier);

    const account = await getUser(userAddress);
    console.log({account});
    return account;
  },
});

export const availableProfitAtom = atom<string>({
  key: 'availableProfitAtom',
  default: '0',
});

export const minDepositSelector = selector<number>({
  key: 'minDepositSelector',
  get: async () => {
    return await getMinDepositValue();
  },
});

export const minWithdrawSelector = selector<number>({
  key: 'minWithdrawSelector',
  get: async () => {
    return await getMinWithdrawValue();
  },
});

export const minReinvestSelector = selector<number>({
  key: 'minReinvestSelector',
  get: async () => {
    return await getMinReinvestValue();
  },
});

export const isRegisteredSelector = selector<boolean | null>({
  key: 'isRegisteredSelector',
  get: async ({get}) => {
    const userInfo = get(userInfoSelector);

    if (!userInfo) {
      return null;
    }

    return userInfo.account !== '0x0000000000000000000000000000000000000000';
  },
});

export const launchTimestampSelector = selector<number>({
  key: 'launchTimestampSelector',
  get: async () => {
    // return 0;
    return await getLaunchTimestamp();
  },
});

export const dailyIncomeSelector = selector<string | null>({
  key: 'dailyIncomeSelector',
  get: async ({get}) => {
    const user = get(userInfoSelector);
    const isRegistered = get(isRegisteredSelector);

    if (!isRegistered || !user) {
      return '0';
    }

    return multiply(user.deposit, 0.02);
  },
});

interface UserData {
  line: number;
  minDeposit: number;
  percents: number;
  missed: number;
  profit: number;
  partners: number;
}

export const userDataSelector = selector<UserData[] | null>({
  key: 'userDataSelector',
  get: async ({get}) => {
    get(userInfoUpdateIdentifier);
    const userInfo = get(userInfoSelector);
    const isRegistered = get(isRegisteredSelector);

    if (!userInfo || !isRegistered) {
      return null;
    }

    const percents = [8,7,6,4,3,2];
    const minDeposit = [0.1,0.5,1,1.5,2,2.5];

    return percents.map((p, i) => ({
      line: i + 1,
      percents: p,
      profit: userInfo.partnersRewardByLine[i],
      minDeposit: minDeposit[i],
      partners: userInfo.partnersCountByLine[i],
      missed: userInfo.missedPartnersRewardByLine[i],
    }));
  },
});

export const balanceUpdateIdentifier = atom<number>({
  key: 'balanceUpdateIdentifier',
  default: 0,
});

export const userBalanceAtom = atom<string>({
  key: 'userBalanceAtom',
  default: '0',
});

export default function AccountStore() {
  const {web3} = useMoralis();
  const [ref, setRef] = useRecoilState(refState);
  const location = useLocation();
  const addressRegExp = /^0x[0-9a-f]{40}$/i;

  const shouldUpdateBalance = useRecoilValue(balanceUpdateIdentifier);
  const shouldUpdateProfit = useRecoilValue(profitUpdateIdentifier);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const isRegistered = useRecoilValue(isRegisteredSelector);
  const setUserBalance = useSetRecoilState(userBalanceAtom);
  const setProfit = useSetRecoilState(availableProfitAtom);
  const updateProfit = useUpdateProfit();

  useEffect(() => {
    // if (cookies && cookies.ref && cookies.ref !== ref) {
    //   setRef(cookies.ref);
    // }
    if (location) {
      const refId = location.pathname.split('/')[1];
      if (addressRegExp.test(refId)) {
        setRef(refId);
      }
    }
  }, [location, ref, setRef]);

  useEffect(() => {
    if (web3 && userAddress) {
      web3?.getBalance(userAddress!).then((result: any) => {
        setUserBalance(Moralis.Units.FromWei(result.toString()));
      });
    }
  }, [web3, userAddress, shouldUpdateBalance, setUserBalance]);

  useEffect(() => {
    if (userAddress && isRegistered) {
      getAvailableProfit(userAddress).then((result) => {
        setProfit(result);
      });
    } else {
      setProfit('0');
    }
  }, [shouldUpdateProfit, userAddress, isRegistered, setProfit]);

  useInterval(() => {
    updateProfit();
  }, 20 * 1000);

  return (<></>);
}

export const useUpdateUserInfo = () => {
  const [shouldUpdateUserInfo, setShouldUpdateUserInfo] = useRecoilState(userInfoUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateUserInfo(shouldUpdateUserInfo + 1);
  }, [shouldUpdateUserInfo, setShouldUpdateUserInfo]);
}

export const useUpdateBalance = () => {
  const [shouldUpdateBalance, setShouldUpdateBalance] = useRecoilState(balanceUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateBalance(shouldUpdateBalance + 1);
  }, [shouldUpdateBalance, setShouldUpdateBalance]);
}

export const useUpdateProfit = () => {
  const [shouldUpdateProfit, setShouldUpdateProfit] = useRecoilState(profitUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateProfit(shouldUpdateProfit + 1);
  }, [shouldUpdateProfit, setShouldUpdateProfit]);
}

export function useIsBalanceEnough() {
  const balance = useRecoilValue(userBalanceAtom);
  const {t} = useTranslation();

  return useCallback((value: number | string, message = t('error.not-enough')) => {
    const isEnough = greaterThanOrEqual(convertStringToNumber(balance), convertStringToNumber(value));

    if (!isEnough && message) {
      toast.error(message, {duration: 5000});
    }

    return isEnough;
  }, [balance, t]);
}
