import Moralis from "moralis";
import contract from './BullFarm.json';
import BigNumber from "bignumber.js";
import contractPromise from "@helpers/contractPromise";
import {bigIntToNumber} from "@helpers/numbers";
import {ENV} from "../constants";

export const BullFarmContract = {
  contractAddress: ENV.BULL_FARM,
  abi: contract.abi,
};

export interface ApiaryRaw {
  owner: string;
  slots: BigNumber;
  bees: BigNumber[];
  items: BigNumber[];
  workStartTime: BigNumber;
  lastClaimTimestamp: BigNumber;
  lastDeferredPayoutTimestamp: BigNumber;
  deferredProfit: BigNumber;
  totalClaimedProfit: BigNumber;
}

interface UserInfoRaw {
  account: string;
  deferredProfit: BigInt;
  deposit: BigInt;
  openLines: BigInt;
  lastDepositUpdate: BigNumber;
  partnersCountByLine: BigInt[];
  partnersRewardByLine: BigInt[];
  missedPartnersRewardByLine: BigInt[];
  upline: string;
  withdraw: BigInt;
}

export interface UserInfo {
  account: string;
  deferredProfit: number;
  deposit: number;
  lastDepositUpdate: number;
  openLines: number;
  partnersCountByLine: number[];
  partnersRewardByLine: number[];
  missedPartnersRewardByLine: number[];
  upline: string;
  withdraw: number;
}

export async function getUser(account: string):Promise<UserInfo> {
  await Moralis.enableWeb3();
  let resp = await Moralis.executeFunction({
    functionName: 'getUser',
    params: {
      account,
    },
    ...BullFarmContract
  }) as unknown as UserInfoRaw;

  console.log({resp});

  const result =  {
    account: resp.account,
    deferredProfit: bigIntToNumber(resp.deferredProfit),
    deposit: bigIntToNumber(resp.deposit),
    lastDepositUpdate: resp.lastDepositUpdate.toNumber(),
    openLines: bigIntToNumber(resp.openLines, false),
    partnersCountByLine: resp.partnersCountByLine.map(v => bigIntToNumber(v, false)),
    partnersRewardByLine: resp.partnersRewardByLine.map(v => bigIntToNumber(v)),
    missedPartnersRewardByLine: resp.missedPartnersRewardByLine.map(v => bigIntToNumber(v)),
    upline: resp.upline,
    withdraw: bigIntToNumber(resp.withdraw),
  };

  return result;
}

export async function getLaunchTimestamp():Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'launchTimestamp',
    ...BullFarmContract
  });

  return (resp as unknown as BigNumber).toNumber();
}

export async function getMinReinvestValue():Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'MIN_REINVEST_VALUE',
    ...BullFarmContract
  });

  return bigIntToNumber(resp as unknown as BigInt);
}

export async function getMinWithdrawValue():Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'MIN_WITHDRAWAL_VALUE',
    ...BullFarmContract
  });

  return bigIntToNumber(resp as unknown as BigInt);
}

export async function getMinDepositValue():Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'MIN_DEPOSIT_VALUE',
    ...BullFarmContract
  });

  return bigIntToNumber(resp as unknown as BigInt);
}

export async function registerAccount(upline: string):Promise<boolean> {
  return contractPromise('register', {upline}, BullFarmContract);
}

export async function registerAndDeposit(upline: string, price: string):Promise<boolean> {
  return contractPromise('registerAndDeposit', {upline}, BullFarmContract, Moralis.Units.ETH(price));
}

export async function depositTokens(price: string):Promise<boolean> {
  return contractPromise('deposit', {}, BullFarmContract, Moralis.Units.ETH(price));
}

export async function reinvest():Promise<boolean> {
  return contractPromise('reinvest', {}, BullFarmContract);
}

export async function withdraw():Promise<boolean> {
  return contractPromise('withdraw', {}, BullFarmContract);
}

export async function calculateProfit(bees: number[], items: number[]):Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'calcPureProfit',
    params: {
      bees,
      items,
      period: 1,
    },
    ...BullFarmContract
  });

  return Moralis.Units.FromWei(resp as unknown as number);
}

export async function getAdmin():Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'admin',
    ...BullFarmContract
  });

  console.log({admin: resp});

  return resp as unknown as string;
}

export async function getAvailableProfit(account: string):Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'calcAvailableProfit',
    params: {
      account,
    },
    ...BullFarmContract
  });

  return Moralis.Units.FromWei(resp as unknown as number);
}

export async function getApiaryMood(owner: string):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'getApiaryMood',
    params: {
      owner,
    },
    ...BullFarmContract
  });

  return (resp as unknown as BigNumber).toNumber();
}
