import {useCallback, useEffect, useState} from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import {ENV} from "../constants";

interface Asset {
  balance: string;
  decimals: number;
  name: string;
  symbol: string;
  token_address: string;
}

interface Price {
  nativePrice?: {
    value: string;
    decimals: number;
    name: string;
    symbol: string;
  },
  usdPrice: number;
  exchangeAddress?: string;
  exchangeName?: string;
}

export const useERC20Balance = (params?: any) => {
  const { account, token } = useMoralisWeb3Api();
  const { isInitialized, chainId, account: walletAddress } = useMoralis();

  const [assets, setAssets] = useState<Asset[]>();
  const [price, setPrice] = useState<Price>();

  useEffect(() => {
    if (isInitialized) {
      fetchERC20Balance().then((balance) => setAssets(balance));
      fetchERC20Price().then((data) => setPrice(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, chainId, walletAddress]);

  const fetchERC20Balance = useCallback(async () => {
    const result = await account
      .getTokenBalances({
        address: params?.address || walletAddress!,
        chain: params?.chain || chainId,
      })
      .then((result) => result);
    return result;
  }, [params, walletAddress, chainId, account]);

  const fetchERC20Price = useCallback(async () => {
     const result = await token
      .getTokenPrice({
        address: ENV.BULL_FARM,
        chain: params?.chain || chainId,
      })
      .then((result) => result);
    return result;
  }, [params, chainId, token]);

  // return { fetchERC20Balance, assets };
  return { fetchERC20Balance, fetchERC20Price, assets, price };
};
