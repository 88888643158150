import { getWrappedNative } from "helpers/networks";
import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { c2, tokenValueTxt } from "@helpers/formatters";
import {ENV} from "../constants";
import {convertStringToNumber} from "@helpers/bignumber";

const IsNative = (address: string) =>
  address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

interface TokenPrice {
  // exchangeAddress?: string;
  // exchangeName?: string;
  // nativePrice: string;
  // decimals: number;
  // symbol?: string;
  // value: string;
  // usdPrice: string;
  nativePrice?: {
    value: string;
    decimals: number;
    name: string;
    symbol: string;
  } | undefined;
  usdPrice: number;
  exchangeAddress?: string | undefined;
  exchangeName?: string | undefined;
}

const useTokenPrice = (options = {chain: '0x' + parseInt(ENV.CHAIN_ID).toString(16), address: ENV.BULL_FARM_FUND}) => {
  const { token } = useMoralisWeb3Api();
  const { isInitialized } = useMoralis();
  const [tokenPrice, setTokenPrice] = useState<TokenPrice>();

  useEffect(() => {
    console.log('CALL USE TOKEN PRICE');
    if (!options || !isInitialized || tokenPrice) return;
    fetchTokenPrice(options).then((price) => {
      setTokenPrice(price);
      // price.usdPrice = c2.format(price.usdPrice);
      // const { value, decimals, symbol } = price.nativePrice as any;
      // price.nativePrice = tokenValueTxt(value, decimals, symbol);
      // setTokenPrice({
      //   usdPrice: c2.format(price.usdPrice),
      //   exchangeAddress: price.exchangeAddress,
      //   exchangeName: price.exchangeName,
      //   decimals: decimals,
      //   symbol: symbol,
      //   value: value,
      //   nativePrice: tokenValueTxt(value, decimals, symbol),
      // });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, options, tokenPrice]);

  const fetchTokenPrice = async (options: any) => {
    const { chain, address } = options;
    const tokenAddress = IsNative(address) ? getWrappedNative(chain) : address;
    // console.log("chain", chain);
    // console.log("address", address);
    // console.log("tokenAddress", tokenAddress);
    return token
      .getTokenPrice({ chain, address: tokenAddress })
      .then((result) => result);
  };
  return { fetchTokenPrice, tokenPrice };
};

export default useTokenPrice;
