import FormattedNumber from "@components/common/FormattedNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingDollar} from "@fortawesome/pro-duotone-svg-icons/faHandHoldingDollar";
import {faCoins} from "@fortawesome/pro-duotone-svg-icons/faCoins";
import {faSackDollar} from "@fortawesome/pro-duotone-svg-icons/faSackDollar";
import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import Preloader from "@components/common/Preloader";

export default function WalletPlaceholder() {
  const {t} = useTranslation();
  return (
    <Suspense fallback={<Preloader/>}>
      <div className="tx-center h2">{t('home.available-profit')}:</div>
      <FormattedNumber
        value={0}
        decimals={6}
        postfix="ETH"
        className="tx-center h1 my-3"
      />

      <div className="wd-sm-300 wd-md-400 mt-3">
        <div className="tx-center wd-100p d-flex justify-content-between">
          <button className="btn btn-outline-primary me-1 wd-100p">
            <FontAwesomeIcon icon={faHandHoldingDollar} className="me-2"/>
            {t('common.withdraw')}
          </button>

          <button className="btn btn-primary ms-1 wd-100p">
            <FontAwesomeIcon icon={faCoins} className="me-2"/>
            {t('common.reinvest')}
          </button>
        </div>

        <div className="mt-3 row mb-1">
          <div className="col-6 mb-2">
            Withdrawn <span className="badge bg-primary tx-dark">
              <FormattedNumber
                value={40}
                decimals={3}
              />
            </span>
          </div>
          <div className="col-6 mb-2 tx-right">
            Available <span className={`badge bg-purple`}>
              <FormattedNumber
                value={15}
                decimals={3}
              />
            </span>
          </div>
          <div className="col-6 mb-2">
            Partners <span className="badge bg-info">
              <FormattedNumber
                value={25}
                decimals={3}
              />
            </span>
          </div>
          <div className="col-6 mb-2 tx-right">
            Maximum <span className="badge bg-dark">
              <FormattedNumber
                value={100}
                decimals={3}
              />
            </span>
          </div>
        </div>

        <div className={`progress bg-dark`}>
          <div
            className="progress-bar bg-primary"
            role="progressbar"
            style={{width: '40%'}}
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          />
          <div
            className="progress-bar bg-info"
            role="progressbar"
            style={{width: '25%'}}
            aria-valuenow={25}
            aria-valuemin={0}
            aria-valuemax={100}
          />
          <div
            className="progress-bar bg-purple"
            role="progressbar"
            style={{width: '15%'}}
            aria-valuenow={15}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>

        <Suspense fallback={<Preloader />}>
          <div className="input-group mt-3 mb-1 wd-100p mx-auto">
            <input
              type="number"
              className="form-control tx-center appearance-none"
              value={0}
              onChange={() => console.log('')}
            />
            <button className="btn btn-primary">
              <FontAwesomeIcon icon={faSackDollar} className="me-2"/>
              {t('common.deposit')}
            </button>
          </div>

          <div className="mt-2 wd-100p">
            {t('common.your-balance')}:
            <button
              className="btn btn-link tx-bold p-0 ms-1"
            >
              <FormattedNumber
                value={200}
                postfix="ETH"
                floor={true}
              />
            </button>
          </div>

          <div className="mt-3 wd-100p tx-center">
            <div className="tx-bold tx-20">{t('common.income')}:</div>

            <div className="d-flex justify-content-between mt-1">
              <div className="badge border-1 border-solid border-primary tx-primary wd-100p p-2 tx-16 mb-1 mb-md-0 me-md-1">
                <FormattedNumber
                  value={0}
                  postfix={`ETH ${t('tick.daily')}`}
                  decimals={4}
                  floor
                />
              </div>

              <div className="badge border-1 border-solid border-primary tx-primary wd-100p p-2 tx-16 mt-1 mt-md-0 ms-md-1">
                <FormattedNumber
                  value={0}
                  postfix={`ETH ${t('tick.weekly')}`}
                  decimals={4}
                  floor
                />
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    </Suspense>
  );
}
