type Chain =
  | "eth"
  | "0x1"
  | "ropsten"
  | "0x3"
  | "rinkeby"
  | "0x4"
  | "goerli"
  | "0x5"
  | "kovan"
  | "0x2a"
  | "polygon"
  | "0x89"
  | "mumbai"
  | "0x13881"
  | "bsc"
  | "0x38"
  | "bsc testnet"
  | "0x61"
  | "avalanche"
  | "0xa86a"
  | "avalanche testnet"
  | "0xa869"
  | "fantom"
  | "0xfa"
  | "cronos"
  | "0x19"
  | "cronos testnet"
  | "0x152";

export const ENV = {
  'CHAIN_ID': process.env.REACT_APP_CHAIN_ID as Chain,
  'BULL_FARM': process.env.REACT_APP_BULL_FARM_CONTRACT_ADDRESS as string,
  'BULL_FARM_FUND': process.env.REACT_APP_BULL_FUND_CONTRACT_ADDRESS as string,
}
