import {useRecoilValue} from "recoil";
import {
  isRegisteredSelector,
  loggedInAccountAtom,
  userDataSelector,
  userInfoSelector,
} from "@stores/accountStore";
import FormattedNumber from "@components/common/FormattedNumber";
import React from "react";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";
import CopyButton from "@components/common/CopyButton";

export default function Partners() {
  const {t} = useTranslation();
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const userData = useRecoilValue(userDataSelector);
  const userInfo = useRecoilValue(userInfoSelector);
  const isRegistered = useRecoilValue(isRegisteredSelector);
  const referralLink = `${window.location.origin}/${userAddress}`;

  const cards = [
    {
      title: t('partners.total-partners'),
      value: userData?.reduce((acc, cur) => acc + cur.partners, 0) || 0,
    },
    {
      title: t('partners.total-earned'),
      value: userData?.reduce((acc, cur) => acc + cur.profit, 0) || 0,
      postfix: 'ETH',
      decimals: 4,
    },
    {
      title: t('partners.open-lines'),
      value: userInfo?.openLines,
      postfix: undefined,
    },
  ];

  return (
    <div className="mt-4 card">
      <div className="card-body">
        <h2 className="mb-4">{t('common.partners')}</h2>

        <div className="rounded-10 border-2 border-solid bg-dark tx-primary border-primary py-2 px-3 mb-4">
          {!isRegistered && (<>{t('partners.link-unavailable')}</>)}
          {isRegistered === true && (
            <>
              <div className="d-flex flex-column flex-md-row">
                <div className="tx-center tx-md-left">
                  <div className="p-2 bg-white d-flex justify-content-center align-content-center">
                    <QRCode value={referralLink} size={130}/>
                  </div>
                </div>
                <div className="ms-md-3 tx-center tx-md-left">
                  <div className="tx-bold tx-20">{t('common.invite-link')}</div>
                  <CopyButton
                    text={referralLink}
                    element={referralLink}
                    className="btn btn-link px-0 d-block wd-100p tx-center tx-md-left tx-18 text-decoration-none word-break"
                    noIcon
                  />

                  <div className="mt-1 mt-lg-4">
                    <CopyButton text={referralLink} element={t('common.copy-link')} className="btn btn-primary"/>
                    <CopyButton text={userAddress || ''} element={t('common.copy-id')} className="btn btn-primary ms-2 ms-md-3"/>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {isRegistered && (
          <div className="row tx-center mb-1">
            {cards.map(({value, postfix, decimals, title}) => {
              return value === null ? null : (
                <div className="col-md-4 mb-3" key={`card-${title.toLowerCase().replace(/ /g, '-')}`}>
                  <div className={`card wd-100p ht-100p bg-dark tx-white`}>
                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                      <div className="tx-16">{title}</div>
                      <FormattedNumber
                        value={value}
                        postfix={postfix}
                        decimals={decimals}
                        className="d-block tx-extrabold tx-20"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {isRegistered && (
          <div className="table-responsive rounded-10 tx-center">
            <table className="table table-bordered">
              <thead className="bg-dark tx-white valign-middle-f">
                <tr>
                  <th>{t('common.line')}</th>
                  <th>{t('common.min-deposit')}</th>
                  <th>{t('common.percents')}</th>
                  <th>{t('common.partners')}</th>
                  <th>{t('common.missed-profit')}</th>
                  <th>{t('common.profit')}</th>
                </tr>
              </thead>
              <tbody>
              {userData && userData.map(({line, minDeposit, missed, profit, percents, partners}) => (
                <tr key={`partner-line-${line}`} className={(userInfo?.openLines || 0) >= line ? 'bg-dark tx-white' : 'bg-dark tx-gray-500'}>
                  <td>{line}</td>
                  <td>{minDeposit}</td>
                  <td>{percents}%</td>
                  <td>{partners}</td>
                  <td>
                    <FormattedNumber
                      value={missed}
                      decimals={4}
                      postfix="ETH"
                    />
                  </td>
                  <td>
                    <FormattedNumber
                      value={profit}
                      decimals={4}
                      postfix="ETH"
                    />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
