import React, {useEffect, useState} from "react";
import {useChain, useMoralis} from "react-moralis";
import Main from "@components/dashboard/Main/Main";
import AccountStore, {loggedInAccountAtom} from "@stores/accountStore";
import Preloader from "@components/common/Preloader";
import {Toaster} from "react-hot-toast";
import {useRecoilState} from "recoil";
import {CookiesProvider} from "react-cookie";
import Moralis from "moralis";
import {useTranslation} from "react-i18next";
import {ENV} from "./constants";
// import MoralisType from "moralis-v1";

function App() {
  const {t} = useTranslation();
  // const { isWeb3Enabled, enableWeb3, isWeb3EnableLoading, isInitialized, isAuthenticated, account, logout } = useMoralis();
  const { isAuthenticated, user, account, enableWeb3 } = useMoralis();
  const [loggedInAccount, setLoggedInAccount] = useRecoilState(loggedInAccountAtom);
  const [lastAccount, setLastAccount] = useState<string | null>(null);
  const { switchNetwork, chainId: moralisChainId } = useChain();
  const [chainId, setChainId] = useState<string | null>(moralisChainId);
  // const [web3, setWeb3] = useState<MoralisType.Web3Provider | undefined>(undefined);

  const isLoggedIn = isAuthenticated && account && loggedInAccount && loggedInAccount === account;
  const isCurrentChainId = chainId === ENV.CHAIN_ID;

  useEffect(() => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (connectorId) {
      // @ts-ignore
      enableWeb3({provider: connectorId, chainId: parseInt(ENV.CHAIN_ID)})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Used while 1st load or change account
  useEffect(() => {
    if (user && user.get("ethAddress") == account) {
      setLoggedInAccount(account);
    } else {
      setLoggedInAccount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, account]);

  useEffect(() => {
    if (window.location.hash === '#clearmydirtybrowser') {
      localStorage.clear();
    }
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated && loggedInAccount && account && lastAccount && lastAccount !== account) {
  //     logout();
  //     setLoggedInAccount(null);
  //     setLastAccount(null);
  //     window.localStorage.removeItem('connectorId');
  //   }
  // }, [logout, lastAccount, setLoggedInAccount, isAuthenticated, loggedInAccount, account]);

  // const handleInit = useCallback(async () => {
  //   const connectorId = window.localStorage.getItem('connectorId');
  //   // @ts-ignore
  //   const customWeb3 = connectorId ? await enableWeb3({ provider: connectorId, chainId: parseInt(ENV.CHAIN_ID) }) : await enableWeb3({ chainId: parseInt(ENV.CHAIN_ID) });
  //   setLoaded(true);
  //   if (!!customWeb3 && web3 && isAuthenticated && isWeb3Enabled && isInitialized && !loggedInAccount && account && !lastAccount) {
  //     console.log('set account', account, {web3: !!web3, isAuthenticated, isWeb3Enabled, isInitialized});
  //     setTimeout(() => {
  //       setLoggedInAccount(account);
  //       setLastAccount(account);
  //     }, 100);
  //   }
  // }, [enableWeb3, lastAccount, web3, isWeb3Enabled, isInitialized, setLoggedInAccount, isAuthenticated, loggedInAccount, account]);
  //
  // useEffect(() => {
  //   handleInit();
  // }, [handleInit]);

  // useEffect(() => {
  //   if (isAuthenticated && isWeb3Enabled && isInitialized && !loggedInAccount && account && !lastAccount) {
  //     setLoggedInAccount(account);
  //     setLastAccount(account);
  //   }
  // }, [lastAccount, isWeb3Enabled, isInitialized, setLoggedInAccount, isAuthenticated, loggedInAccount, account]);
  //
  // useEffect(() => {
  //   Moralis.onChainChanged(function (chain) {
  //     setChainId(chain);
  //   });
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (web3 && web3.network && web3.network.chainId) {
  //     setChainId(web3?.network?.chainId?.toString() || null);
  //   } else {
  //     setChainId(null);
  //   }
  // });

  // if(account) {
    return (
      <React.Suspense fallback={<Preloader/>}>
        <CookiesProvider>
          {(!isLoggedIn || (isLoggedIn && (chainId === null || isCurrentChainId))) && (
            <>
              <AccountStore/>
              <Main/>
            </>
          )}
          {isLoggedIn && chainId !== null && !isCurrentChainId && (
            <div id="home" className="tx-center">
              <div className="mt-5 h3 px-2">{t('error.wrong-network')}</div>
              <button
                className="btn btn-lg btn-primary mt-2 mx-auto wd-250 wd-lg-auto"
                onClick={() => switchNetwork(`0x${(5).toString(16)}`)}
              >
                {t('common.switch-network')}
              </button>
            </div>
          )}
          <Toaster/>
        </CookiesProvider>
      </React.Suspense>
    )
}

export default App;