import React, {Suspense, useEffect, useState} from "react";
import {useMoralis, useNativeBalance} from "react-moralis";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  balanceUpdateIdentifier,
  loggedInAccountAtom,
  profitUpdateIdentifier,
  refState,
  userInfoSelector
} from "@stores/accountStore";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import { connectors } from "@components/Account/config";
import Preloader from "@components/common/Preloader";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormattedNumber from "@components/common/FormattedNumber";
// import Faq from "@components/dashboard/Faq/Faq";
import Wallet from "@components/dashboard/Wallet/Wallet";
import {faWallet} from "@fortawesome/pro-duotone-svg-icons/faWallet";
import Partners from "@components/dashboard/Partners/Partners";
import WalletPlaceholder from "@components/dashboard/Wallet/WalletPlaceholder";
import {useERC20Balance} from "@hooks/useERC20Balance";
import {ENV} from "../../../constants";
import useTokenPrice from "@hooks/useTokenPrice";
import {multiply} from "@helpers/bignumber";
import Moralis from "moralis";
import logo from "@assets/images/ethbull.svg";

export default function Main() {
  const {t} = useTranslation();
  const { isWeb3Enabled, isInitialized, authenticate, isAuthenticated, isWeb3EnableLoading, isAuthenticating, web3, enableWeb3 } = useMoralis();
  const { assets } = useERC20Balance({
    address: ENV.BULL_FARM_FUND,
  });
  const { data: tokenBalance, getBalances } = useNativeBalance({address: ENV.BULL_FARM});
  const { data: fundBalance } = useNativeBalance({address: ENV.BULL_FARM_FUND});
  // const { tokenPrice } = useTokenPrice({chain: '0x1', address: '0x6Dca182ac5E3f99985bC4Ee0f726d6472aB1ec55'});
  const { tokenPrice: ushiPrice } = useTokenPrice({chain: '0x1', address: '0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55'});
  // const { tokenPrice: ethPrice } = useTokenPrice({chain: '0x1', address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'});
  const [userAddress, setUserAddress] = useRecoilState(loggedInAccountAtom);
  const setRef = useSetRecoilState(refState);
  const {refId} = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const isBalanceUpdated = useRecoilValue(balanceUpdateIdentifier);
  const isProfitUpdated = useRecoilValue(profitUpdateIdentifier);
  // const isBrowserWeb3Enabled = window.hasOwnProperty('web3');
  const [localUser, setLocalUser] = useState<string | null>(null);
  const userInfo = useRecoilValue(userInfoSelector);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  // const handleShow = () => setUserAddress('0x0B4821FEA907C6B5033c92D17Ca1953c5a7Dd4e2');

  const login = async (provider: string) => {
    if (!isWeb3Enabled) {
      await enableWeb3();
    }

    setLoading(true);
    setShow(false);

    try {
      await authenticate({
        // @ts-ignore
        provider,
        signingMessage: t('home.moralis-login'),
        chainId: parseInt(ENV.CHAIN_ID),
        onError: (error: any) => console.error(error),
      })
        .then(async (user) => {
          setLoading(false);
          if (user) {
            setLocalUser(user.get("ethAddress"));
            window.localStorage.setItem("connectorId", provider);
          } else {
            toast.error(t('error.no-web3'));
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.error(
        t('error.unknown-while-auth'),
        {duration: 7000}
      );
    }
  }

  const cards = [
    {
      title: 'TVL',
      value: isInitialized ? Moralis.Units.FromWei(tokenBalance.balance || 0) : null,
      postfix: 'ETH',
      color: 'primary',
    },
    {
      title: t('common.insurance-fund'),
      value: isInitialized ? multiply(Moralis.Units.FromWei(assets?.find((v) => v.symbol === 'USHI')?.balance || 0), ushiPrice?.usdPrice || 0) : null,
      postfix: '$',
      color: 'info',
      decimals: 0,
    },
    {
      title: 'APR',
      value: 1095,
      postfix: '%',
      color: 'purple',
    },
    {
      title: t('common.your-deposit'),
      value: isInitialized && userInfo ? userInfo.deposit : undefined,
      postfix: 'ETH',
      color: 'secondary',
      decimals: 5,
    },
  ];

  useEffect(() => {
    if (refId && parseInt(refId).toString() === refId) {
      setRef(refId);
      navigate('/');
    }
  }, [refId, setRef, navigate]);

  useEffect(() => {
    if (web3 && isAuthenticated && isWeb3Enabled && isInitialized && localUser) {
      setTimeout(() => {
        setUserAddress(localUser);
      }, 100);
    }
  }, [localUser, web3, isWeb3Enabled, isAuthenticated, isInitialized, setUserAddress]);

  // useEffect(() => {
  //   getBalances();
  // }, [isBalanceUpdated, isProfitUpdated]);
  useEffect(() => {
    console.log({assets});
  }, [assets]);

  if (!isInitialized || isWeb3EnableLoading) {
    return (
      <div id="home" className="container py-5">
        <Preloader/>
      </div>
    );
  }

  return (
    <Suspense fallback={<Preloader/>}>
      <div id="home" className="container py-5">
        <div className="tx-center">
          <img src={logo} title="ETH Bull" alt="ETH BUll" width={300} className="mx-auto"/>
        </div>

        <div className="row tx-center tx-24 justify-content-between">
          {cards.map(({value, postfix, title, color, decimals}) => {
            return value === undefined ? null : (
              <div className="col-md-6 col-lg-3 mb-3" key={`card-${title.toLowerCase().replace(/ /g, '-')}`}>
                <div className={`card wd-100p ht-100p bg-${color}`}>
                  <div className="card-body d-flex flex-column align-items-center justify-content-center">
                    <FormattedNumber
                      value={value}
                      postfix={postfix}
                      decimals={decimals}
                      className="d-block tx-extrabold tx-30"
                    />
                    <div>{title}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="mn-ht-400 ht-auto d-flex align-items-center justify-content-center mt-1 card p-3 p-lg-5 overflow-hidden">
          {!userAddress && (
            <div className="blurred">
              <button className="btn btn-lg btn-primary wd-250 wd-lg-auto" onClick={handleShow} disabled={isAuthenticating || isLoading}>
                <FontAwesomeIcon icon={faWallet} className="me-2"/>
                {t('home.connect')}
              </button>
            </div>
          )}
          {!userAddress ? <WalletPlaceholder/> : <Wallet/>}
        </div>

        {!!userAddress && (
          <Partners/>
        )}

        {/*<Faq/>*/}

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          {isLoading && <Preloader/>}
          <Modal.Header closeButton>
            <Modal.Title>
              {t('home.connect')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="row">
              {connectors.map(({ title, icon, connectorId }, key) => (
                <div
                  className="col-6 mb-4 cur-pointer ht-80 d-flex flex-column align-items-center justify-content-center"
                  key={key}
                  onClick={() => login(connectorId)}
                >
                  <img src={icon} alt={title} className="wd-50 mb-2" />
                  <div className="tx-center">{title}</div>
                </div>
              ))}
            </div>
            {(isAuthenticating || isLoading) && <Preloader/>}
          </Modal.Body>
        </Modal>
      </div>
    </Suspense>
  );
}
